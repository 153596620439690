
ul{
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.wrap{
  position: relative;
  width: 1200px;
  height: 600px;
}
.list{
  position: relative;
  width: 1000px;
  height: 507px;
  margin: 40px auto;
}
.list .item{
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
}
.list .item.active{
  z-index: 10;
}
.list .item img{
  width: 100%;
}
.wrap .btn{
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  width: 100px;
  height: 100px;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: all .3s;
  z-index: 20;
}
.wrap .btn.left{
  left: 0px;
}
.wrap .btn.right{
  right: 0px;
}

#LeftBut_2 ,#RightBut_2{
  display: none;
}