.teachingmode{
  background: #fff;
  padding-top: 80px;
}
.childResult {
  font-size:35px;
  font-family:PingFang SC;
  font-weight:bold;
  line-height:40.98px;
  color:rgba(51,51,51,1);
  text-align: center;
  padding-top: 100px;
  opacity:1;
}
.eleYOYO{
  width: 100%;
  height: 22px;
  text-align: center;
  font-size:16px;
  font-family:PingFang SC;
  font-weight:500;
  line-height:22px;
  color:rgba(119,119,119,1);
  opacity:1;
  margin: 16px auto 0;
}

.FiveTeaching{
  padding-bottom: 100px;
  background: #fff;

  div{
    margin-top: 40px;
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content:center;
    -ms-flex-pack: center;
  }
}

.Teachingmode{
  background:rgba(255,251,232,1);
  padding-bottom: 100px;
  opacity:1;

  .model{
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content:center;
    -ms-flex-pack: center;
  }

  .teachWay{
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content:center;
    -ms-flex-pack: center;

    .way{
      width: 255px;
      margin-top: 56px;
      float: left;

      img {
        width: 160px;
        height: 160px;
        margin-left: 48px;
      }

      h4 {
        text-align: center;
        font-size:24px;
        font-family:PingFang SC;
        font-weight:500;
        line-height:33px;
        color: #333333;
        opacity:1;
        margin-top: 10px;
      }

      p {
        font-size:18px;
        font-family:PingFang SC;
        font-weight:500;
        line-height:30px;
        color: #777777;
        opacity:1;
        margin-top: 20px;
      }
    }

    .way_1{
      margin-left: 219px;
      margin-right: 218px;
    }
  }
}

.company{
  padding-bottom: 100px;

  .company_childResult{
    margin-top: 60px;
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content:center;
    -ms-flex-pack: center;

    .companyLeft{
      margin-right: 233px;
    }

    .companyRight{
      margin-top: 110px;
    }
  }
}