.bottom_get {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 160px;
  z-index: 24;
  // -webkit-transform: translateY(180px);
  // -o-transform: translateY(180px);
  // transform: translateY(180px);
  transition: transform .3s ease-in-out;
  transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out,-o-transform .3s ease-in-out;
  min-width: 1080px;

  .bottomfooter {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100px;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-shadow: 0 2px 16px 0 rgba(0,0,0,.12);
    box-shadow: 0 2px 16px 0 rgba(0,0,0,.12);
    background:rgba(38,38,38,0.6);
    opacity:1;
    
    .bottom_free {
      position: absolute;
      bottom: 0;
      padding-left: 307px;
      -webkit-flex-basis: 1200px;
      -ms-flex-preferred-size: 1200px;
      flex-basis: 1200px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      height: 100px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-transition: padding .3s ease-out;
      -o-transition: padding .3s ease-out;
      transition: padding .3s ease-out;
  
      .bottom_img {
        position: absolute;
        bottom: 0;
        left: 0;
        background: url('../images/twochilds.png') no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
        width: 285px;
        height: 180px;
        opacity: 1;
        // -webkit-transition: all .3s ease-out;
        // -o-transition: all .3s ease-out;
        // transition: all .3s ease-out;
      }
  
      .bottom_valueOF {
        font-size:30px;
        font-family:PingFang SC;
        font-weight:bold;
        line-height:100px;
        color:rgba(255,255,255,1);
        opacity:1;
        margin-right: 20px;
  
        span {
          font-size:40px;
          font-family:PingFang SC;
          font-weight:800;
          line-height:56px;
          color:rgba(255,203,49,1);
          opacity:1;
          margin: 0 8px;
        }
      }
  
      .bottom_input {
        position: relative;
        -webkit-flex-basis: 569px;
        -ms-flex-preferred-size: 569px;
        flex-basis: 569px;
        overflow: hidden;
  
        .bottom_number {
          margin-right: 5px;
          background: rgba(255,255,255,1);
          border-radius: 10px;
          width: 282px;
          height: 60px;
          padding-left: 20px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          font-size: 16px;
          line-height: 42px;
          color: rgba(153,153,153,1);
          border: none;
          outline: none;
          float: left;
        }
  
        #bottom_error {
          position: absolute;
          right: 300px;
          top: 10px;
          line-height: 42px;
          margin-left: 10px;
          color: #ff5c5c;
          font-size: 16px;
        }

        .bottom_button {
          float: left;
          background: rgba(255,203,49,1);
          border-radius: 10px;
          color: #fff;
          width: 282px;
          height: 60px;
          line-height: 60px;
          font-size: 30px;
          cursor: pointer;
          -webkit-transition: all .2s;
          -o-transition: all .2s;
          transition: all .2s;
          border: none;
          outline: none;
        }

        .bottom_button:hover {
          background:rgba(255,191,0,1);
        }
      }
    }
  }
}

@media screen and (max-width:1313px){

  .bottom_img {
    display: none;
  }
  .bottom_valueOF{
    margin-left: -285px;
  }
}