.MuiTypography-body1 {
  background-image: url("../images/bodybackground.png");
  background-size: 100% 100%;
}

.Myclass {
  width: 100%;
  overflow: hidden;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.myclass {
  width: 1200px;
  padding-bottom: 14%;
  padding-top: 140px;

  .Myclass_ClassType {
    height: 42px;
    padding: 40px 32px;
    background: rgba(255, 255, 255, 1);
    border-radius: 20px 20px 0 0;
    border-bottom: 2px solid rgba(238, 238, 238, 1);
    // overflow: hidden;
    position: relative;

    h2 {
      float: left;
      font-size: 35px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 49px;
      color: rgba(39, 98, 204, 1);
    }
    .Myclass_Switch {
      float: right;
      overflow: hidden;
      cursor: pointer;

      span {
        float: left;
        height: 33px;
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 33px;
        color: rgba(39, 98, 204, 1);
        margin-right: 18px;
      }

      img {
        display: inline-block;
        margin-top: 10px;
      }
    }

    .Myclass_Transform {
      position: absolute;
      top: 110px;
      right: 0;
      width: 341px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 3px 6px rgba(204, 204, 204, 0.7);

      .Myclass_jian {
        width: 45px;
        height: 45px;
        position: absolute;
        top: -24px;
        right: 24px;
        background-color: #ffffff;
        transform: rotate(45deg);
        box-shadow: -3px -3px 6px rgba(204, 204, 204, 0.7);
      }

      ul {
        li {
          cursor: pointer;
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 500;
          line-height: 33px;
          color: rgba(51, 51, 51, 1);
          padding: 23px 0 23px 16px;
          border-bottom: 1px solid rgba(204, 204, 204, 1);
        }

        .Myclass_Type {
          border: none;
        }
      }
    }
  }

  .Myclass_Recently {
    background-color: #ffffff;
    overflow: hidden;
    padding: 40px 23px 22px 32px;
    border-radius: 0 0 20px 20px;

    .Myclass_Recent {
      float: left;

      h3 {
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 33px;
        color: rgba(51, 51, 51, 1);
      }

      p {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 25px;
        color: rgba(119, 119, 119, 1);
        margin-top: 26px;

        span {
          margin-left: 10px;
        }
      }
    }

    .Myclass_continue {
      cursor: pointer;
      float: right;
      margin-top: 13px;
    }
  }
}

.Myclass_Back {
  width: 1200px;
  border-radius: 20px;
  padding-bottom: 60px;
  background-color: #ffffff;

  .myclasslevel {
    overflow: hidden;
    margin-top: 60px;
    padding: 40px 32px 0px 32px;
    border-bottom: 2px solid rgba(238, 238, 238, 1);
    position: relative;

    .myclasslevel2{
      cursor: pointer;
      float: left;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 28px;
      color: rgba(119, 119, 119, 1);
      margin-top: 11px;
      margin-right: 68px;
    }

    .myclasslevel1 {
      cursor: pointer;
      float: left;
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 30px;
      color: rgba(51, 51, 51, 1);
      margin-right: 59px;
      border-bottom: 6px solid rgba(255, 203, 49, 1);
      padding-bottom: 32px;
    }
  }
}
.myclassVideo {

  .myclass_class {
    width: 100%;
    margin-left: 20px;

    .myclassvideo {
      float: left;
      width: 372px;
      height: 300px;
      margin-right: 20px;
      margin-top: 40px;
      box-shadow: 3px 3px 6px rgba(204, 204, 204, 0.4);

      .myclassImg {
        width: 100%;
        height: 218px;
        border-radius: 10px 10px 0px 0px;
        position: relative;
      
        .Myclass_BackImg {
            width: 100%;
            height: 100%;
        }
        .myclass_mask{
          width: 100%;
          height: 299px;
          border-radius: 10px 10px 0px 0px;
          background: rgba($color: #000000, $alpha: 0.25);
          cursor: pointer;
          position: absolute;
          left: 0;
          top: 0;

          .myclass_img {
            width: 80px;
            height: 80px;
            // display: none;

            img {
              width: 100%;
              margin: 100px 0 0 147px;
            }
          }
        }
        .myclassmask {
          width: 100%;
          height: 218px;
          border-radius: 10px 10px 0px 0px;
          background: rgba($color: #000000, $alpha: 0.25);
          cursor: pointer;
          position: absolute;
          left: 0;
          top: 0;
          display: none;

          .myclass_img {
            width: 80px;
            height: 80px;
            // display: none;

            img {
              width: 100%;
              margin: 70px 0 0 155px;
            }
          }
        }
      }
      &.myclass_index{
        transform: scale(1.05, 1.05);

        .myclassmask{
          display: block;
        }
      }

      // .myclassImg {
      //     background: url('../images/drainage_1.png');
      //     background-size: 100% 100%;
      // }

      .myclasstext {
        width: 100%;
        height: 81px;
        box-shadow: 0px 3px 6px rgba(204, 204, 204, 0.4);
        background-color: #fff;
        overflow: hidden;

        .myclassname {
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 81px;
          color: rgba(51, 51, 51, 1);
          opacity: 1;
          padding-left: 15px;
        }

        .myclassButton {
          float: right;
          font-size: 14px;
          margin-top: 20px;
          background: rgba(249, 203, 0, 1);
          color: #fff;
          padding: 10px;
          border-radius: 20px;
          text-decoration: none;
          margin-right: 15px;
          cursor: pointer;
          border: 0;
          outline: none;
        }
      }
    }

    .clear{
      clear: both;
    }
  }
}

.Myclass_Modal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  display: none;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 9999;
  -webkit-animation: index__fade-in___oyvx2;
  -o-animation: index__fade-in___oyvx2;
  animation: index__fade-in___oyvx2;
  -webkit-animation-duration: 0.3s;
  -o-animation-duration: 0.3s;
  animation-duration: 0.3s;

  .myclass_classvideo {
    width: 700px;
    height: 600px;
    border: 2px solid #cccccc;
    padding: 20px 20px 0 20px;
    background: #fff;
    overflow: hidden;

    #firstTitle{
      float: left;
    }

    .myclass_video_title {
        font-size: 30px;
        color: #333333;
    }

    #myVideo{
      width: 100%;
      margin-top: 20px;
    }

    .myclass_Closed {
      float: right;
      width: 25px;
      cursor: pointer;
    }

    .myclass_details {
      overflow: hidden;

      .myclass_Dl{
          float: left;
          width: 500px;
      }

      .myclass_Br{
          float: right;
          margin-top: 40px;
      }

      h3 {
          font-size: 24px;
          margin-top: 10px;
      }

      .myclass_info {
        font-size: 18px;
        color: #777777;
        margin-top: 5px;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }

      .myclass_Button {
        font-size: 20px;
        background: rgba(249, 203, 0, 1);
        color: #fff;
        padding: 10px;
        border-radius: 20px;
        text-decoration: none;
        margin-right: 20px;
        cursor: pointer;
        border: 0;
        outline: none;
      }

      .myclass_Button:hover {
        background: rgba(255, 191, 0, 1);
      }
    }
  }
}