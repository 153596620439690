.processc{
  background: #fff;
  padding-top: 80px;
}
.childResult {
  font-size:35px;
  font-family:PingFang SC;
  font-weight:bold;
  line-height:40.98px;
  color:rgba(51,51,51,1);
  text-align: center;
  padding-top: 100px;
  opacity:1;
}
.howtosee{
  margin-bottom: 100px;
}

.handsup{
  background:rgba(255,251,232,1);
  opacity:1;
  padding-bottom: 100px;
}

.preparationc{
  padding-bottom: 100px;
}

.HowToSee ,.HandsUp ,.Preparationc{
  overflow: hidden;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-justify-content:center;
  -ms-flex-pack: center;
  margin-top: 60px;
}