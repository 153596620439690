.PopupWindow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  display: none;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.2);
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 9999;
  -webkit-animation: index__fade-in___oyvx2;
  -o-animation: index__fade-in___oyvx2;
  animation: index__fade-in___oyvx2;
  -webkit-animation-duration: .3s;
  -o-animation-duration: .3s;
  animation-duration: .3s;

  .PopupEX {
    position: relative;
    width: 310px;
    height: 389px;
    padding: 51px 30px 0 260px;
    background: 50%/contain url(../images/popup.png) no-repeat;

    .Close_popup {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 26px;
      height: 26px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      position: absolute;
      top: 20px;
      right: 20px;
      border-radius: 50%;
      cursor: pointer;

      img {
        display: inline-block;
        width: 27px;
        height: 27px;
      }
    }

    .popup_free {
      width: 100%;
      position: relative;

      .popup_get_1{
        font-size:30px;
        font-family:PingFang SC;
        font-weight:bold;
        line-height:42px;
        color:rgba(51,51,51,1);
        opacity:1;
      }

      .popup_get_2 {
        font-size:26px;
        font-family:PingFang SC;
        font-weight:bold;
        line-height:37px;
        color:rgba(51,51,51,1);
        opacity:1;
        margin-top: 11px;

        span {
          font-size:35px;
          font-family:PingFang SC;
          font-weight:bold;
          line-height:49px;
          color:rgba(6,98,171,1);
          opacity:1;
        }
      }
    }

    .popup_quick {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: left;
      -webkit-justify-content: left;
      -ms-flex-pack: left;
      justify-content: left;
      width: 340px;
      margin: 0 auto;
      margin-top: 23px;

      .popup_quickly {
        display: inline-block;
        vertical-align: top;

        .popup_input {
          height: 49px;
          width: 310px;
          color: #333;
          background: #fff;
          border-radius: 10px;
          padding: 14px 16px;
          position: relative;
          margin-bottom: 25px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;

          input {
            width: 100%;
            height: 100%;
            padding: 0;
            font-size:16px;
            font-family:PingFang SC;
            font-weight:500;
            line-height:22px;
            color:rgba(153,153,153,1);
            opacity:1;
            border: none;
            outline: none;
          }
        }

        .popup_YZM {
          height: 49px;
          width: 310px;
          color: #333;
          background: #fff;
          border-radius: 10px;
          padding: 14px 16px;
          position: relative;
          margin-bottom: 47px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;

          input {
            width: 100%;
            height: 100%;
            padding: 0;
            font-size:16px;
            font-family:PingFang SC;
            font-weight:500;
            line-height:22px;
            color:rgba(153,153,153,1);
            opacity:1;
            outline: none;
            border: none;
          }

          span {
            display: inline-block;
            position: absolute;
            right: 0;
            top: 12px;
            width: 106px;
            height: 25px;
            padding-left: 16px;
            border-left: 2px solid rgba(204,204,204,1);
            font-size:16px;
            font-family:PingFang SC;
            font-weight:500;
            line-height:22px;
            color:rgba(6,98,171,1);
            opacity:1;
            cursor: pointer;
          }
        }

        .popup_EXP {
          cursor: pointer;
        }
      }
    }
  }
}