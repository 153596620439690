.curriculum{
  width: 100%;
  background:#fff;
  padding-top: 80px;
}

.curriculumback{
  width: 100%;
  padding-top: 10vh;
  padding-bottom: 10vh;
  background: #fff;

  img{
    width: 50%;
    padding-left: 25%;
  }
}

.classgetback{
  width: 100%;
  padding-bottom: 6vh;
  background-color:rgba(249,203,0,0.09);

  .classget {
    padding-top: 6vh;
    margin-left: 43%;

    img{
      width: 25%;
    }
  }

  .ClassGET{
    width: 51%;
    margin-left: 25%;
    margin-top: 6vh;
    overflow: hidden;

    .get{
      width: 48%;
      float: left;

      img{
        width: 100%;
      }
    }

    .series{
      width: 50%;
      margin-left: 2%;
      margin-top: 6vh;
      float: left;

      .aseriesof{
        width: 100%;
        overflow: hidden;

        .Bseries{
          float: left;
          width: 35%;
          background:rgba(255,148,1,1);
          opacity:1;
          border-radius:10px;
          text-align: center;
          color: #fff;
          font-size:0.82vw;
          font-family:PingFang SC;
          font-weight:500;
          line-height:4vh;
          padding-left: 1%;
          padding-right: 1%;
          margin-left: 23%;
          cursor: pointer;
        }
        .Aseries{
          float: left;
          width: 35%;
          border:1px solid rgba(204,204,204,1);
          opacity:1;
          border-radius:10px;
          font-size:0.82vw;
          font-family:PingFang SC;
          font-weight:500;
          line-height:4vh;
          color:rgba(153,153,153,1);
          padding-left: 1%;
          padding-right: 1%;
          text-align: center;
          margin-left: 2%;
          cursor: pointer;
        }
      }

      .ABseries ,.abseries{
        margin-top: 4vh;

        h3,h4{
          text-align: right;
        }

        h3{
          font-size:1.25vw;
          font-family:PingFang SC;
          font-weight:bold;
          line-height:36px;
          color:rgba(51,51,51,1);
          opacity:1;
        }

        h4 {
          font-size:0.7vw;
          font-family:PingFang SC;
          font-weight:500;
          line-height:36px;
          color:rgba(119,119,119,1);
          opacity:1;
        }

        p{
          width: 99%;
          font-size:0.9vw;
          font-family:PingFang SC;
          font-weight:500;
          line-height:36px;
          color:rgba(119,119,119,1);
          opacity:1;
        }
      }

      .ABseries{
        display: block;
      }
      
      .abseries{
        display: none;
      }
    }
  }
}

.abilityback{
  width: 100%;
  padding-top: 10vh;
  padding-bottom: 10vh;
  background: #fff;

  img{
    width: 50%;
    padding-left: 25%;
  }
}

.extension{
  width: 100%;
  padding-bottom: 10vh;

  .knowledgeback{
    margin-left: 43%;

    img{
      width: 25%;
    }
  }

  .classtext{
    width: 50%;
    margin: 6vh auto;
    font-size:0.98vw;
    font-family:PingFang SC;
    font-weight:500;
    line-height:4vh;
    color:rgba(29,26,26,1);
    opacity:1;
  }

  .knowledgeIMG{
    width: 70%;
    overflow: hidden;
    padding-left: 22%;

    .KnowledgeExtension{
      width: 8%;
      float: left;
      padding-left: 4.5%;

      img{
        width: 100%;
      }

      p{
        text-align: center;
        font-size:0.98vw;
        font-family:PingFang SC;
        font-weight:500;
        line-height:4vh;
        color:rgba(29,26,26,1);
        opacity:1;
      }
    }
  }
}