.PrivateTabIndicator-colorPrimary-3{
  height: 6px !important;
}

.MuiAppBar-root{
  width: 100% !important;
  height: 80px !important;
}
.MuiPaper-root ,.MuiAppBar-root ,.MuiAppBar-positionStatic ,.MuiAppBar-colorDefault ,.MuiPaper-elevation4, #headerYO {
  margin: auto !important;
  overflow: hidden !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  justify-content: center !important;
  -webkit-justify-content:center !important;
  -ms-flex-pack: center !important;
}

.YOyo{
  width: 275px;
  height: 34px;
  padding-top: 23px;
  padding-left: 18%;

  img {
    height: 100%;
    cursor: pointer;
  }
}

.hometabs{
  width: 50%;

  .MuiTabs-flexContainer .MuiTab-root span{
    width: 110px !important;
    font-size:18px;
    font-family:PingFang SC;
    font-weight:500;
    line-height:25px;
    color:rgba(51,51,51,1);
    opacity:1;
  }

  .tabhome{
    margin-left: 25%;
  }
}

.gotostudy{
  cursor: pointer;
  position: absolute;
  left: 74%;
  top: 15px;
  width:120px;
  background:rgba(255,203,49,1);
  opacity:1;
  border-radius:30px;
  padding: 10px 10px;
  text-decoration: none !important;

  span {
    font-size:18px;
    font-family:PingFang SC;
    font-weight:500;
    line-height:30px;
    color:rgba(255,255,255,1) !important;
    opacity:1;
    margin-left: 6px;
  }
}

.MuiAppBar-positionStatic{
  position: fixed !important;
}

.bannerimg{
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content:center;
    -ms-flex-pack: center;

    img{
      height: 100%;
    }
}

.banner_free{
  position: absolute;
  top: 215px;
  left:70%;
  z-index: 20;
  width: 330px;
  height: 296px;

  .banner_registered{
    // padding: 34px 24px 0;
    height: 100%;
    background: #fff;
    -webkit-box-shadow: 0 8px 20px 0 rgba(0,0,0,0.2);
    box-shadow: 0 8px 20px 0 rgba(0,0,0,0.2);
    border-radius: 30px;
    padding-top: 34px;

    .bannerFree ,.banner_valueOF{
      text-align: center;
      font-size:30px;
      font-family:Nowar Warcraft Rounded CN;
      font-weight:bold;
      line-height:42px;
      color:rgba(51,51,51,1);
      opacity:1;
      letter-spacing: 0;
    }
    .banner_valueOF span{
      letter-spacing: 0;
      text-align: center;
      font-size:40px;
      font-family:PingFang SC;
      font-weight:bold;
      line-height:56px;
      color:rgba(255,203,49,1);
      opacity:1;
    }

    .banner_input {    
      margin:16px 24px 0;
      padding: 19px 43px;
      padding-right: 0;
      width: 238px;
      background:rgba(235,235,235,1);
      opacity:1;
      border-radius: 33px;

      input{
        display: inline-block;
        width: 140px;
        vertical-align: middle;
        font-size:20px;
        font-family:PingFang SC;
        font-weight:500;
        line-height:28px;
        color:rgba(153,153,153,1);
        background: rgba(235,235,235,1);
        opacity:1;
        border: none;
        outline: none;
      }

      #errorformat {    
        color: #ff5c5c;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .banner_button{
      margin-left: 11px;
      cursor: pointer;
    }
  }
}

@media screen and (max-width:1650px) {
  .YOyo{
    padding-left: 2%;
  }

  .tabhome{
    margin-left: 46% !important;
  }
  
  .gotostudy{
    position: absolute;
    left:88%;
    top: 15px;
  }

  .banner_free {
    position: absolute;
    left:72%;
  }
}

@media screen and (max-width:1500px) {
  .YOyo{
    padding-left: 2%;
  }

  .tabhome{
    margin-left: 42% !important;
  }

  .banner_free {
    position: absolute;
    left:70%;
  }
}

@media screen and (max-width:1400px) {

  .tabhome{
    margin-left: 42% !important;
  }

  .banner_free {
    position: absolute;
    left:70%;
  }
}

@media screen and (max-width:1313px) {

  .tabhome{
    margin-left: 40% !important;
  }

  .banner_free {
    position: absolute;
    left:70%;
  }
}

@media screen and (max-width:1100px) {

  .tabhome{
    margin-left: 36% !important;
  }

  .banner_free {
    position: absolute;
    left:88%;
  }
}

@media screen and (max-width:850px) {
  .tabhome{
    margin-left: 35% !important;
  }
  .banner_free {
    position: absolute;
    left:105%;
  }
}

@media screen and (max-width:770px){

  .tabhome{
    margin-left: 37% !important;
  }
  .banner_free {
    position: absolute;
    left:110%;
  }
}
@media screen and (max-width:500px){
  .banner_free {
    position: absolute;
    left:200%;
  }
}

@media screen and (max-width:400px){
  .banner_free {
    position: absolute;
    left:226%;
  }
}