.MuiAppBar-colorPrimary {
    background-color: white !important;
}

.MuiTabs-root {
    width: 100% !important;
}

.MuiTabs-flexContainer {

  .MuiTab-root {
        padding: 0 !important;
        font-weight: 500 !important;

    span{
        font-family:PingFang SC;
        line-height:80px !important;
        color:rgba(51,51,51,1);
        opacity:1;
    }
  }
}

.MuiBox-root{
  padding: 0 !important;
}

.MuiAppBar-colorDefault{
  background-color: white !important;
}

.PrivateTabIndicator-colorPrimary-3 {
  background-color: rgba(249,203,0,1) !important;
}

.MuiTab-wrapper{
    color: #000 !important;
}

.PrivateTabIndicator-colorSecondary-4 ,.PrivateTabIndicator-colorSecondary-6{
    background-color: rgba(249,203,0,1) !important;
}

.MuiAppBar-root {
    flex-direction: initial !important;
}

.personalcentertitle {
    width: 160px !important;
    // margin-left: 55% !important;
    font-size: 18px !important;
}

.myworktitle {
    width: 160px !important;
    // margin-left: 55% !important;
    font-size: 18px !important;
}

.myclasstitle{
    width: 160px !important;
    font-size: 18px !important;
    margin-left: 34% !important;
}

.PrivateTabIndicator-root-4 ,.PrivateTabIndicator-colorSecondary-6 {
    height: 6px !important;
    width: 160px !important;
}

.yo{
    width: 275px;
    height: 34px;
    padding-top: 23px;
    padding-left: 18.4%;

    img{
        height: 100%;
        cursor: pointer;
    }
}

// .workIMG{
//     width: 1%;
//     position: absolute;
//     left:63.5%;
//     top: 3.3vh;
// }
// .personIMG{
//     width: 1%;
//     position: absolute;
//     left: 73%;
//     top: 3.3vh;
// }

@media (min-width: 600px){
    .MuiTab-root {
        min-width: 0px !important; 
    }
}

@media screen and (max-width:1400px) {
    .yo{
      padding-left: 6%;
    }
  
    .myclasstitle{
      margin-left: 44% !important;
    }
  }
  
@media screen and (max-width:1313px) {
    .yo{
        padding-left: 2%;
    }
  
    .myclasstitle{
        margin-left: 46% !important;
    }
}