.myclassfooter {
    width: 100%;
    height:117px;
    background:rgba(241,241,241,1);
    opacity:1;
    padding-top: 60px;
    -webkit-box-shadow: inset 0 1px 0 0 #ececec;
    box-shadow: inset 0 1px 0 0 #ececec;
    position: relative;
    z-index: 15;
    text-align: center;
    margin-bottom: -5vh;

    .foot-message {
        position: relative;
        height:17px;
        font-size:12px;
        font-family:PingFang SC;
        font-weight:500;
        line-height:17px;
        color:rgba(119,119,119,1);
        opacity:1;
    }

    .footer-1 {
        position: relative;
        font-size:12px;
        font-family:PingFang SC;
        font-weight:500;
        line-height:17px;
        color:rgba(119,119,119,1);
        opacity:1;
        margin-top: 20px;

        span{
            cursor: pointer;
        }
    }
}

// @media screen and (max-width:770px) {
//     .myclassfooter {
    
//         .foot{
//             width: 80%;
//             margin-left: 10%;
//             padding-top: 2%;
            
            
//             .foot-message{

//                 p{
//                     margin-left: 0%;
//                     line-height: 1vh;
//                 }
//             }

//             .myclasscode {
//                 width: 30%;
//                 margin-right: 0;
    
//                 img {
//                     width: 100%;
//                 }
//             } 
//         }
//     }
// }