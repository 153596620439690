.Mywork_Back {
    width: 100%;
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content:center;
    -ms-flex-pack: center;
    background-image: url("../images/bodybackground.png");
    background-size: 100% 100%;

    .Mywork_Box {
        width: 1200px;
        margin-top: 140px;
        margin-bottom: 100px;
        border-radius:10px;
        background: #ffffff;
        position: relative;

        .Mywork_ClassType {
            height: 130px;
            padding: 40px 32px 0 32px;
            background:rgba(242,242,242,1);
            border-radius:10px 10px 0 0;
            // overflow: hidden;
            position: relative;

            h2 {
                float: left;
                font-size:35px;
                font-family:PingFang SC;
                font-weight:bold;
                line-height:49px;
                color:rgba(39,98,204,1);
            }
            .Mywork_Switch {
                float: right;
                overflow: hidden;
                cursor: pointer;

                span {
                    float: left;
                    height:33px;
                    font-size:24px;
                    font-family:PingFang SC;
                    font-weight:500;
                    line-height:33px;
                    color:rgba(39,98,204,1);
                    margin-right: 18px;
                }

                img {
                    display: inline-block;
                    margin-top: 10px;
                }
            }

            .Mywork_Transform {
                position: absolute;
                top: 115px;
                right: 0;
                width: 341px;
                background:rgba(255,255,255,1);
                box-shadow:0px 3px 6px rgba(204,204,204,0.7);

                .Mywork_jian {
                    width: 45px;
                    height: 45px;
                    position: absolute;
                    top: -24px;
                    right: 24px;
                    background-color: #ffffff;
                    transform: rotate(45deg);

                }

                ul {

                    li {
                        cursor: pointer;
                        font-size:24px;
                        font-family:PingFang SC;
                        font-weight:500;
                        line-height:33px;
                        color:rgba(51,51,51,1);
                        padding: 23px 0 23px 16px;
                        border-bottom:1px solid rgba(204,204,204,1);
                    }

                    .Mywork_Type {
                        border: none;
                    }
                }
            }
        }

        .Mywork_ChapterLevel{
            position: absolute;
            left: 0;
            top: 115px;
            margin-top: -10px;

            ul {
                overflow: hidden;
    
                li {
                    float: left;
                }


                .Mywork_ChapterLevel_02{
                    font-size:16px;
                    font-family:PingFang SC;
                    font-weight:500;
                    line-height:25px;
                    color:rgba(255,255,255,1);
                    padding: 8px 32px 7px 32px;
                    background:rgba(138,209,245,1);
                    border-radius:20px 20px 0px 0px;
                    margin-top: 25px;
                    margin-right: 10px;
                    cursor: pointer;
                }

                .Mywork_ChapterLevel_01 {
                    font-size:20px;
                    font-family:PingFang SC;
                    font-weight:bold;
                    line-height:24px;
                    color:rgba(51,51,51,1);
                    padding: 19px 32px 8px 32px;
                    background:rgba(255,255,255,1);
                    border-radius: 20px 20px 0 0;
                    margin-top: 14px;
                    margin-right: 10px;
                    cursor: pointer;
                }

                // .Mywork_ChapterLevel_02 {
                //     margin-left: 10px;
                //     margin-right: 10px;
                // }
            }
        }

        .Mywork_class {
            margin-top: 55px;
            padding-left: 32px;
            overflow: hidden;

            h3 {
                font-size:30px;
                font-family:PingFang SC;
                font-weight:500;
                line-height:42px;
                color:rgba(51,51,51,1);
                margin-bottom: 40px;
            }
            .mywork_Workcode{

                #Mywork_Workcode {
                    float: left;
                    margin-right: 20px;
                }
                
                .clear{
                    clear: both;
                }

                .Mywork_WorkCode{
                    width: 365px;
                    margin-bottom: 60px;
                    border-radius: 10px 10px 0px 0px;
                    box-shadow: 0px 5px 10px rgba(238,238,238,1);
    
                    .Mywork_Img {
                        width: 100%;
                        height: 215px;
                        border-radius: 10px 10px 0px 0px;
                        position: relative;
    
                        .Myclass_BackImg{
                            width: 100%;
                            height: 100%;
                            border-radius: 10px 10px 0px 0px;
                        }
    
                        .Mywork_Mask {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 215px;
                            border-radius: 10px 10px 0px 0px;
                            background: rgba($color: #212121, $alpha: 0.32);
                            cursor: pointer;
                            display: none;
    
                            .mywork_Button {
                                position: absolute;
                                left: 123px;
                                top: 87px;
                                font-size:30px;
                                font-family:PingFang SC;
                                font-weight:500;
                                line-height:42px;
                                color:rgba(255,255,255,1);
                                border: none;
                                outline: none;
                                background: none;
                                cursor: pointer;
                            }
                        }
                    }
    
                    &.mywork_index{
                        transform: scale(1.05 ,1.05);
                    
                        .Mywork_Mask{
                            display: block;
                        }
                    }
    
                    .Mywork_Title {
                        overflow: hidden;
                        box-shadow:0px 3px 6px rgba(0,0,0,0.16);
    
                        .Mywork_Works {
                            float: left;
                            padding: 16px 0 16px 15px;
    
                            h4 {
                                font-size:24px;
                                font-family:PingFang SC;
                                font-weight:500;
                                line-height:33px;
                                color:rgba(51,51,51,1);
                            }
    
                            p{
                                font-size:16px;
                                font-family:PingFang SC;
                                font-weight:500;
                                line-height:22px;
                                color:rgba(153,153,153,1);
                                margin-top: 10px;
                            }
                        }
    
                        .Mywork_State ,.Mywork_ITG ,.Mywork_NotThrough ,.Mywork_Practise{
                            float: right;
                            border-radius:19px;
                            padding: 7px 28px;
                            margin-top: 41px;
                            margin-right: 15px;
                            font-size:18px;
                            font-family:PingFang SC;
                            font-weight:500;
                            line-height:25px;
                            color:rgba(255,255,255,1);
                        }
    
                        .Mywork_State {
                            background:rgba(103,194,58,1);
                        }
    
                        .Mywork_ITG {
                            background:rgba(255,203,49,1);
                        }
    
                        .Mywork_NotThrough {
                            background:rgba(243,82,82,1);
                        }
    
                        .Mywork_Practise {
                            background:rgba(138,209,245,1);
                        }
                    }
                }
            }
        }
    }
}