.makeStyles-paper-3{
    width: 25% ;
    border: 0 ;
    border-radius: 30px ;
    outline: 0;
}

.oldpw ,.newpw ,.returnpw {
    padding-left: 5%;
    margin-left: 16%;
    width: 63%;
    border-radius: 10px;
    line-height: 5.5vh;
    outline: 0; 
}

.oldpw {
    margin-top: 8%;
}

.newpw ,.returnpw{
    margin-top: 8%;
}

.surepw {
    margin-left: 16%;
    width: 69%;
    border-radius: 10px;
    line-height: 5.5vh;
    margin-top: 8%;
    margin-bottom: 8%;
    background-color: rgba(249,203,0,1);
    color: #fff;
    outline: 0; 
    cursor: pointer;
}

.sendcaptcha {
    position: absolute;
    right: 19.25%;
    top: 33%;
    width: 27%;
    line-height: 5.5vh;
    background-color: rgba(249,203,0,1);
    color: #fff;
    border: 0;
    border-radius: 10px;
    outline: 0;
    cursor: pointer;
}

.Close{
    width: 6%;
    position: absolute;
    right: 4%;
    top: 4%;
    cursor: pointer;
}

@media screen and (max-width:770px) {
    .sendcaptcha {
        position: absolute;
        right: 22%;
        top: 31.8%;
        line-height: 5.2vh;
    }
}