.Successful {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  display: none;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.2);
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 9999;
  -webkit-animation: index__fade-in___oyvx2;
  -o-animation: index__fade-in___oyvx2;
  animation: index__fade-in___oyvx2;
  -webkit-animation-duration: .3s;
  -o-animation-duration: .3s;
  animation-duration: .3s;

  .Successful_code {
    position: relative;
    width: 550px;
    height: 397px;
    padding: 43px 0 34px 50px;
    background: 50%/contain url(../images/popupcode.png) no-repeat;

    .Successful_Close {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 28px;
      height: 28px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      position: absolute;
      top: 36px;
      right: 20px;
      border-radius: 50%;
      cursor: pointer;

      img {
        display: inline-block;
        width: 28px;
        height: 28px;
      }
    }

    .Successful_zero {
      position: relative;
      margin-left: 60px;

      p{
        font-size:30px;
        font-family:PingFang SC;
        font-weight:bold;
        line-height:42px;
        color:#fff;
        opacity:1;
        margin-top: 20px;
        text-shadow: -10px -2px 8px rgb(255, 145, 0) ;

        span {
          font-size:40px;
          font-family:PingFang SC;
          font-weight:bold;
          line-height:56px;
          color:rgba(6,98,171,1);
          opacity:1;
          margin-left: 8px;
          margin-right: 8px;
          text-shadow: none;
        }
      }
    }

    .yoyoQrcode {
      width: 188px;
      height: 188px;
      margin:20px 0 20px 160px;

      img {
        height: 100%;
      }
    }

    .Successful_focus {
      width: 100%;
      margin-left: 80px;
      padding-bottom: 34px;

      .Successful_receive {
        font-size:20px;
        font-family:PingFang SC;
        font-weight:bold;
        line-height:36px;
        color:#fff;
        opacity:1;

        span {
          font-family:PingFang SC;
          font-weight:bold;
          color:rgba(6,98,171,1);
          opacity:1;
        }

        .Successful_class {
          font-size:25px;
          line-height:36px;
        }
      }
    }
  }
}