.personalcenter {
    width: 100%;
    padding-top: 100px;
    padding-bottom: 200px;
    
    .personalCenter {
        width: 1200px;
        margin: 60px auto;
        // overflow: hidden;

        .cishu-one ,.cishu-two ,.cishu-three {
            // float: left;
            width: 386px;
            height: 180px;
            display: inline-block;
        }

        .cishu-one {
            background-image: url('../images/cishuone.png');
        }

        .cishu-two {
            margin-left: 20px;
            background-image: url('../images/cishutwo.png');
        }

        .cishu-three {
            background-image: url('../images/cishuthree.png');
            margin-left: 20px;
        }

        .cishu {
            margin-top: 31px;
            margin-left: 64px;
            font-size:24px;
            font-family:PingFang SC;
            font-weight:bold;
            line-height:40px;
            color:rgba(255,255,255,1);
            opacity:1;
        }

        .cishunumber {
            font-size: 60px;
            font-family:PingFang SC;
            font-weight:bold;
            line-height:90px;
            color:rgba(255,255,255,1);
            opacity:1;
            margin-left: 64px;
        }

        .cishuicon ,.cishutext {
            font-size:30px;
            font-family:PingFang SC;
            font-weight:bold;
            line-height:80px;
            color:rgba(255,255,255,1);
            opacity:1;
        }
    }

    .personaldata {
        width: 1200px;
        margin: 60px auto;
        overflow: hidden;
        // margin: 0px auto;

        .personalmessage ,.account {
            width: 590px;
            height: 320px;
            float: left;
        }

        .personalmessage{
            background-image: url('../images/person.png');
            // background-color: red;

            .personalwrite{
                width: 100%;
                margin-top: 32px;
                overflow: hidden;

                img{
                    float: left;
                    width: 5%;
                    margin-left: 32px;
                }

                .message{
                    float: left;
                    margin-left: 8px;
                    font-size:24px;
                    font-family:PingFang SC;
                    font-weight:bold;
                    line-height:33px;
                    color:rgba(255,255,255,1);
                    opacity:1;
                }
            }

            .personal {
                width: 100%;
                margin-top: 24px;
                overflow: hidden;

                .personalsave {
                    float: left;
                    width: 20%;
                    margin-left: 6%;
                    margin-top: 2%;
                    
                    #personalImG{
                        width: 100%;
                    }

                }

                .personalnicheng {
                    float: left;
                    margin-right: 12%;

                    .personalclear-1 ,.personalclear-2{
                        width: 100%;
                        margin-left: 2%;

                        .nicheng {
                            font-size:20px;
                            font-family:PingFang SC;
                            font-weight:bold;
                            line-height:29px;
                            color:rgba(255,255,255,1);
                            opacity:1;
                        }

                        .qianming {
                            font-size:14px;
                            font-family:PingFang SC;
                            font-weight:500;
                            line-height:29px;
                            color:rgba(255,255,255,1);
                            opacity:1;
                        }

                        #Nicheng {
                            width: 100%;
                            line-height: 50px;
                            margin-left: 6px;
                        }

                        #Qianming{
                            width: 100%;
                            margin-left: 6px;
                        }
                        img{
                            width: 7%;
                        }
                    }
                    
                    .personalclear-1{
                        margin-top: 22px;
                    }
                }
            }

            .writemessage {
                width:110px;
                background:rgba(255,255,255,1);
                opacity:1;
                border-radius:10px;
                margin-left: 448px;
                color:rgba(249,203,0,1);
                font-size: 16px;

                p{
                    line-height: 29px;
                }
            }
        }

        .account {
            margin-left: 20px;
            background-image: url('../images/person.png');
            background-size: 100% 100%;

            .accountsetup {
                width: 100%;
                margin-top: 5%;
                overflow: hidden;

                img{
                    width: 5%;
                    margin-left: 32px;
                    float: left;
                }

                span{
                    float: left;
                    margin-left: 8px;
                    font-size:1.25vw;
                    font-family:PingFang SC;
                    font-weight:bold;
                    line-height:30px;
                    color:rgba(255,255,255,1);
                    opacity:1;
                }
            }

            .accountpassword {
                width: 100%;
                margin-left: 32px;

                h3 {
                    font-size:22px;
                    font-family:PingFang SC;
                    font-weight:bold;
                    line-height:29px;
                    color:rgba(255,255,255,1);
                    opacity:1;
                    margin-top: 30px;
                }

                p{
                    font-size:20px;
                    font-family:PingFang SC;
                    font-weight:500;
                    line-height:29px;
                    color:rgba(255,255,255,1);
                    opacity:1;
                    margin-top: 18px;
                }

                button {
                    width:110px;
                    background:rgba(255,255,255,1);
                    opacity:1;
                    border-radius:10px;
                    margin-top: 36px;
                    margin-left: 416px;
                    color:rgba(249,203,0,1);
                    font-size: 16px;
                    line-height: 29px;
                    border: 0;
                    outline: none;
                    cursor: pointer;
                }
            }

            // .accounttelephone {
            //     margin-left: 7.14%;
            //     margin-top: 20px;

            //     h3 {
            //         height:25px;
            //         font-size:18px;
            //         font-family:PingFang SC;
            //         font-weight:500;
            //         line-height:29px;
            //         color:rgba(255,255,255,1);
            //         opacity:1;
            //     }

            //     p{
            //         height:20px;
            //         font-size:14px;
            //         font-family:PingFang SC;
            //         font-weight:500;
            //         line-height:29px;
            //         color:rgba(255,255,255,1);
            //         opacity:1;
            //         margin-top: 5px;
            //     }

            //     Button {
            //         width:22.45%;
            //         height:30px;
            //         background:rgba(255,255,255,1);
            //         opacity:1;
            //         color:rgba(249,203,0,1);
            //         border-radius:5px;
            //         margin-top: 10px;
            //     }
            // }
        }
    }
}

#NiCheng ,#QianMing {
    display: none;
    border: 0;
    outline: none;
    background: rgba(249,203,0,0.1);
    width: 90%;
}

#NiCheng {
    font-size:1vw;
    font-family:PingFang SC;
    font-weight:bold;
    line-height:2.5vh;
    opacity:1;
}

#QianMing {
    font-size:0.8vw;
    font-family:PingFang SC;
    font-weight:bold;
    line-height:2vh;
    opacity:1;
}

#WRiteM {
    display: none;
}
// #personaliMG{
//     width: 100%;
//     cursor: pointer;
//     display: none;
// }

.tz_borderp {
    width: 1154px;
    height:83px;
    background:rgba(255,126,97,1);
    opacity:1;
    border-radius:10px;
    margin: auto;
    padding: 11px 23px;
    // overflow: hidden;
    position: relative;

    .tz_borders {
        width:1154px;
        height:83px;
        background:rgba(255,255,255,1);
        border:1px dashed rgba(66,55,53,1);
        opacity:1;
        border-radius:42px;
        position: relative;

        .tz_picarrow {
            width: 116px;
            height: 69px;
            position: absolute;
            bottom: 0px;
            left: 290px;
            overflow: hidden;

            img ,span {
                float: left;
            }

            span {
                position: absolute;
                top: 17px;
                left: 17px;
                font-size:20px;
                font-family:PingFang SC;
                font-weight:bold;
                line-height:19px;
                color:rgba(29,49,47,1);
                opacity:1;
            }
        }
    }

    .tz_picchild ,.tz_picarrow ,.tz_borderclass ,.tz_programming{
        float: left;
    }

    .tz_picchild {
        width: 168px;
        height: 168px;
        position: absolute;
        left: 5px;
        bottom: -15px;
        z-index: 24;
    }

    .tz_borderclass {
        font-size:24px;
        font-family:PingFang SC;
        font-weight:500;
        line-height:29px;
        color:rgba(29,49,47,1);
        opacity:1;
        margin-left: 428px;
        margin-top: 25px;
    }

    .tz_programming {
        width:159px;
        height:42px;
        background:rgba(255,203,49,1);
        opacity:1;
        border-radius:27px;
        margin-left: 245px;
        margin-top: 11px;
        padding: 4px 14px 8px 19px;
        box-shadow: 0px 7px 1px rgba(242,181,0,1);;
        cursor: pointer;

        span {
            font-size:30px;
            font-family:PingFang SC;
            font-weight:500;
            line-height:36px;
            color:rgba(255,255,255,1);
            opacity:1;
        }
    }
}