#ImgApp{
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content:center;
    -ms-flex-pack: center;
    position: relative;
}

#leftB{
    width: 70px;
    height: 91px;
    padding-top: 9px;
    padding-left: 30px;
    border-radius: 50%;
    border: 2px solid #999999;
    font-size: 60px;
    position: absolute;
    left: 0;
    top: 200px;
    color: #999999;
}
#rightB{
    width: 65px;
    height: 91px;
    padding-top: 9px;
    padding-left: 35px;
    border-radius: 50%;
    border: 2px solid #999999;
    font-size: 60px;
    position: absolute;
    left: 1100px;
    top: 200px;
    color: #999999;
}