/*margin的样式初始化*/
body,h1,h2,h3,h4,h5,h6,p,ul,ol,dl,dd{
	margin:0;
}

/*padding的样式初始化*/
ul,ol {
	padding:0;
}

/*列表的样式初始化*/
ul,ol,li {
	list-style:none;
}

/*font-style的样式初始化*/
em {
	font-style: normal;
}

/*下划线的样式初始化*/
a {
	text-decoration: none;
}
/*BFC的方法*/
.clear:after{
	content: "";display: block;clear: both;
}
clear {
	zoom:1;
}
/*光斑效果 需求父级需要相对定位*/
.light:after {
	content: "";
	width:100%;
	height:100%;
	position: absolute;
	left:0;
	top:0;
	background:  linear-gradient(90deg,
		rgba(255,255,255,0) 0px,
		rgba(255,255,255,1) 120px,
		rgba(255,255,255,1) 130px,
		rgba(255,255,255,0) 140px
	) -140px 0px no-repeat;
	animation: 1.3s light;
}

@keyframes light {
	0%{
		background-position: -140px 0px;
	}
	100% {
		background-position: 1000px 0px;
	}
}
/*延迟光斑效果 父级需要相对定位3*/
.lightDelay:after {
	content: "";
	width:100%;
	height:100%;
	position: absolute;
	left:0;
	top:0;
	background:  linear-gradient(90deg,
		rgba(255,255,255,0) 0px,
		rgba(255,255,255,1) 120px,
		rgba(255,255,255,1) 130px,
		rgba(255,255,255,0) 140px
	) -140px 0px no-repeat;
	animation: 1.3s 0.4s lightDelay;
}

@keyframes lightDelay {
	0%{
		background-position: -140px 0px;
	}
	100% {
		background-position: 1000px 0px;
	}
}