.avatar-uploader > .ant-upload {
  width: 100%;
  border-radius: 50%;
  opacity: 1;
  // display: none;

  img{
    width: 100%;
    border-radius: 50%;
  }
}

#user-photo {
  width: 100%;
  border-radius: 50%;
  cursor: pointer;
}
