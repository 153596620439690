.bottom_foot {
  width: 100%;
  height:117px;
  background:rgba(241,241,241,1);
  opacity:1;
  padding-top: 60px;
  -webkit-box-shadow: inset 0 1px 0 0 #ececec;
  box-shadow: inset 0 1px 0 0 #ececec;
  position: relative;
  z-index: 15;
  text-align: center;
  margin-bottom: 80px;

  .bottom-message {
      position: relative;
      height:17px;
      font-size:12px;
      font-family:PingFang SC;
      font-weight:500;
      line-height:17px;
      color:rgba(119,119,119,1);
      opacity:1;
  }

  .bottom-dizhi {
      position: relative;
      font-size:12px;
      font-family:PingFang SC;
      font-weight:500;
      line-height:17px;
      color:rgba(119,119,119,1);
      opacity:1;
      margin-top: 20px;

      span{
        cursor: pointer;
      }
  }
}