.loginbox {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  background: url("../images/loginback.png") no-repeat;
  opacity: 1;
  margin-bottom: -2.2vh;

  .Login_BOX {
    width: 1200px;
    margin-top: 100px;

    .Login_Box {
      overflow: hidden;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;

      .loginlogo {
        margin: 190px 57px 0 25px;
      }

      .Login_box {
        width: 450px;
        height: 450px;
        background: rgba(255, 255, 255, 0.38);
        border: 1px solid rgba(255, 255, 255, 1);
        border-radius: 30px;
        padding: 25px;
        margin-top: 80px;

        .logininput {
          width: 450px;
          height: 450px;
          background: rgba(255, 255, 255, 1);
          border-radius: 30px;

          p{
            font-size:30px;
            font-family:PingFang SC;
            font-weight:500;
            line-height:42px;
            color:rgba(51,51,51,1);
            text-align: center;
            padding-top: 45px;
          }

          .Login_way{
            width: 330px;
            overflow: hidden;
            margin-left: 40px;
            padding: 45px 40px 17px 0;
            border-bottom: 1px solid rgba(238,238,238,1);

            .Login_way_1 ,.Login_way_2 {
              float: left;
            }

            .Login_way_1 {
              font-size:30px;
              font-family:PingFang SC;
              font-weight:500;
              line-height:42px;
              color:rgba(51,51,51,1);
              cursor: pointer;
              position: relative;

              .Login_line {
                width:120px;
                height:5px;
                background:rgba(255,203,49,1);
                position: absolute;
                left: 0;
                bottom: -17px;
              }
            }

            .Login_way_2 {
              font-size:25px;
              font-family:PingFang SC;
              font-weight:500;
              line-height:36px;
              color:rgba(119,119,119,1);
              margin-left: 61px;
              margin-top: 5px;
              cursor: pointer;
              position: relative;

              .Login_line {
                width:120px;
                height:5px;
                background:rgba(255,203,49,1);
                position: absolute;
                left: 0;
                bottom: -17px;
                display: none;
              }
            }
          }

          form {
            width: 100%;

            .input-1,
            .input-2 {
              width: 360px;
              height: 46px;
              background: rgba(255, 255, 255, 1);
              border: 1px solid rgba(204, 204, 204, 1);
              opacity: 1;
              border-radius: 14px;
              outline: none;
              font-size:14px;
              font-family:PingFang SC;
              font-weight:500;
              line-height:46px;
              color:#333333;
              display: block;
              margin: 0 auto;
              padding-left: 10px;
            }

            .input-1 {
                margin-top: 47px;
            }
            .input-2 {
                margin-top: 33px;
            }

            .Loginin {
              background: none;
              border: none;
              outline: none;
              margin-top: 43px;
              margin-left: 25px;

              img {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
iframe {
  display: none;
}
