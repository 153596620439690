.MuiPaper-elevation4{ 
  box-shadow: 0px 2px 4px -1px #cccccc !important;
}

.hometitle{
  width: 100%;
  background: #fff;
  padding-top: 80px;
}

.childResult {
  font-size:35px;
  font-family:PingFang SC;
  font-weight:bold;
  line-height:40.98px;
  color:rgba(51,51,51,1);
  text-align: center;
  padding-top: 100px;
  opacity:1;
}
.eleYOYO{
  width: 100%;
  height: 22px;
  text-align: center;
  font-size:16px;
  font-family:PingFang SC;
  font-weight:500;
  line-height:22px;
  color:rgba(119,119,119,1);
  opacity:1;
  margin: 16px auto 0;
}

.results{
  margin-bottom: 100px;

  .home_children{
    padding-top: 60px;
    height: 440px;
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content:center;
    -ms-flex-pack: center;

    .home_TuWen{
      width: 380px;
      height: 420px;
      margin-right: 20px;
      box-shadow:0px 3px 6px rgba(204,204,204,0.5);
      border-radius: 20px;
      cursor: pointer;

      img{
        width: 100%;
        border-radius: 20px 20px 0 00;
      }

      .home_Wenzi ,.home_XWenzi{
        margin-left: 20px;
        margin-right: 20px;
        text-align: center;
      }

      .home_Wenzi{
        font-size: 30px;
        font-family: Nowar Warcraft Rounded CN;
        font-weight: bold;
        line-height: 41px;
        color: #FEAE35;
        margin-top: 20px;
        opacity:1;
      }

      .home_XWenzi{
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 30px;
        color: #333333;
        margin-top: 15px;
        opacity:1;
      }
    }
  }
}

.fuse{
  background: #FFFBE8;

  .home_fuse{
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content:center;
    -ms-flex-pack: center;
    margin-top: 60px;

    .home_video{
      width: 637px;
      height: 358.3125px;
      border-radius: 20px;
      border: 1px solid #707070;
      outline: none;
      margin-right: 40px;
      
      video{
        width: 637px;
        height: 358.3125px;
        border-radius: 20px;
        outline: none;
      }
    }

    .home_introduce{

      h4{
        font-size: 28px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 44px;
        color: #F59F1A;
        opacity: 1;
        margin-top: 35px;
      }

      p{
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 40px;
        color: #777777;
        opacity: 1;
        margin-top: 50px;
      }
    }
  }

  .home_subject{
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content:center;
    -ms-flex-pack: center;
    padding-bottom: 100px;
    margin-top: 60px;

    .home_Sub{
      margin-right: 96px;
    }

    p{
      text-align: center;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 40px;
      color: #1D1A1A;
      opacity: 1;
      margin-top: 10px;
    }
  }
}

.home_advanced{

  .home_eleYOYO ,.home_eleyoyo{
    width: 100%;
    height: 22px;
    text-align: center;
    font-size:16px;
    font-family:PingFang SC;
    font-weight:500;
    line-height:22px;
    color:rgba(119,119,119,1);
    opacity:1;
  }

  .home_eleYOYO{
    margin: 16px auto 0;
  }

  .home_eleyoyo{
    margin: 10px auto 0;
    margin-bottom: 132px;
  }

  .home_objective{
    // overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content:center;
    -ms-flex-pack: center;

    .home_step{
      position: relative;

      img{
        margin-right: 96px;
      }

      .home_step_1{
        position: absolute;
        left: 40px;
        top: 16px;
        width: 145px;
        height: 145px;
        background: #FFF9E7;
        border-radius: 50%;
        opacity: 1;
        padding: 23px 0 0 23px;
        cursor: pointer;

        .home_step_one{
          width: 122px;
          height: 122px;
          background: #FEC93A;
          border-radius: 50%;
          opacity: 1;

          h3{
            font-size: 45px;
            font-family: Nowar Warcraft Rounded CN;
            font-weight: bold;
            line-height: 41px;
            color: #FFFFFF;
            opacity: 1;
            text-align: center;
            padding-top: 20px;
          }

          p{
            font-size: 20px;
            font-family: Nowar Warcraft Rounded CN;
            font-weight: bold;
            line-height: 34px;
            color: #FFFFFF;
            opacity: 1;
            text-align: center;
          }
        }
      }

      .home_step_2{
        position: absolute;
        left: 706px;
        top: -72px;
        width: 127px;
        height: 127px;
        background: #FFF6DC;
        border-radius: 50%;
        opacity: 1;
        padding: 20px 0 0 20px;
        cursor: pointer;

        .home_step_two{
          width: 107px;
          height: 107px;
          background: #FFAB38;
          border-radius: 50%;
          opacity: 1;

          h3{
            font-size: 40px;
            font-family: Nowar Warcraft Rounded CN;
            font-weight: bold;
            line-height: 36px;
            color: #FFFFFF;
            opacity: 1;
            text-align: center;
            padding-top: 15px;
          }

          p{
            font-size: 18px;
            font-family: Nowar Warcraft Rounded CN;
            font-weight: bold;
            line-height: 30px;
            color: #FFFFFF;
            opacity: 1;
            text-align: center;
            margin-top: 5px;
          }
        }
      }

      .home_step_3{
        position: absolute;
        left: 1034px;
        top: 94px;
        width: 141.5px;
        height: 141.5px;
        background: #FFEEE2;
        border-radius: 50%;
        opacity: 1;
        padding: 22.5px 0 0 22.5px;
        cursor: pointer;

        .home_step_three{
          width: 120px;
          height: 120px;
          background: #FF953E;
          border-radius: 50%;
          opacity: 1;

          h3{
            font-size: 45px;
            font-family: Nowar Warcraft Rounded CN;
            font-weight: bold;
            line-height: 41px;
            color: #FFFFFF;
            opacity: 1;
            text-align: center;
            padding-top: 20px;
          }

          p{
            font-size: 20px;
            font-family: Nowar Warcraft Rounded CN;
            font-weight: bold;
            line-height: 33px;
            color: #FFFFFF;
            opacity: 1;
            text-align: center;
          }
        }
      }

      .home_step_4{
        position: absolute;
        left: 809px;
        top: 315px;
        width: 127px;
        height: 127px;
        background: #FFF1EC;
        border-radius: 50%;
        opacity: 1;
        padding: 20px 0 0 20px;
        cursor: pointer;

        .home_step_four{
          width: 107px;
          height: 107px;
          background: #FF7846;
          border-radius: 50%;
          opacity: 1;

          h3{
            font-size: 40px;
            font-family: Nowar Warcraft Rounded CN;
            font-weight: bold;
            line-height: 36px;
            color: #FFFFFF;
            opacity: 1;
            text-align: center;
            padding-top: 15px;
          }

          p{
            font-size: 18px;
            font-family: Nowar Warcraft Rounded CN;
            font-weight: bold;
            line-height: 30px;
            color: #FFFFFF;
            opacity: 1;
            text-align: center;
            margin-top: 5px;
          }
        }
      }

      .home_step_5{
        position: absolute;
        left: 574px;
        top: 494px;
        width: 147px;
        height: 147px;
        background: #FFEFE4;
        border-radius: 50%;
        opacity: 1;
        padding: 23px 0 0 23px;
        cursor: pointer;

        .home_step_five{
          width: 124px;
          height: 124px;
          background: #FF5C4E;
          border-radius: 50%;
          opacity: 1;

          h3{
            font-size: 45px;
            font-family: Nowar Warcraft Rounded CN;
            font-weight: bold;
            line-height: 41px;
            color: #FFFFFF;
            opacity: 1;
            text-align: center;
            padding-top: 20px;
          }

          p{
            font-size: 20px;
            font-family: Nowar Warcraft Rounded CN;
            font-weight: bold;
            line-height: 33px;
            color: #FFFFFF;
            opacity: 1;
            text-align: center;
            margin-top: 5px;
          }
        }
      }

      .home_step_6{
        position: absolute;
        left: 1067px;
        top: 604px;
        width: 127px;
        height: 127px;
        background: #FFE3E6;
        border-radius: 50%;
        opacity: 1;
        padding: 20px 0 0 20px;
        cursor: pointer;

        .home_step_six{
          width: 107px;
          height: 107px;
          background: #FF4355;
          border-radius: 50%;
          opacity: 1;

          h3{
            font-size: 40px;
            font-family: Nowar Warcraft Rounded CN;
            font-weight: bold;
            line-height: 36px;
            color: #FFFFFF;
            opacity: 1;
            text-align: center;
            padding-top: 15px;
          }

          p{
            font-size: 18px;
            font-family: Nowar Warcraft Rounded CN;
            font-weight: bold;
            line-height: 30px;
            color: #FFFFFF;
            opacity: 1;
            text-align: center;
            margin-top: 5px;
          }
        }
      }

      .home_explanation_1{
        position: absolute;
        left: 228px;
        top: 38px;
        width: 374px;
        height: 102px;
        background: #FFFFFF;
        box-shadow: 0px 10px 20px rgba(226, 226, 226, 0.76);
        border-radius: 20px;
        padding: 39px 52px 31px 52px;
        opacity: 1;
        display: none;

        .home_explanation_jian{
          width: 60px;
          height: 60px;
          box-shadow: -5px 5px 10px rgba(226, 226, 226, 0.5);
          transform: rotate(45deg);
          background: #FFFFFF;
          position: absolute;
          left: -30px;
          top: 60px;
        }

        p{
          width: 374px;
          height: 102px;
          font-size: 20px;
          font-family: Nowar Warcraft Rounded CN;
          font-weight: 400;
          line-height: 35px;
          color: #333333;
          opacity: 1;
        }
      }

      .home_explanation_2{
        position: absolute;
        left: 198px;
        top: -90px;
        width: 374px;
        height: 172px;
        background: #FFFFFF;
        box-shadow: 0px 10px 20px rgba(226, 226, 226, 0.76);
        border-radius: 20px;
        padding: 39px 52px 31px 52px;
        opacity: 1;
        display: none;

        .home_explanation_jian{
          width: 60px;
          height: 60px;
          box-shadow: 5px -5px 10px rgba(226, 226, 226, 0.5);
          transform: rotate(45deg);
          background: #FFFFFF;
          position: absolute;
          left: 450px;
          top: 90px;
        }

        p{
          width: 374px;
          height: 102px;
          font-size: 20px;
          font-family: Nowar Warcraft Rounded CN;
          font-weight: 400;
          line-height: 35px;
          color: #333333;
          opacity: 1;
        }
      }

      .home_explanation_3{
        position: absolute;
        left: 528px;
        top: 108px;
        width: 374px;
        height: 102px;
        background: #FFFFFF;
        box-shadow: 0px 10px 20px rgba(226, 226, 226, 0.76);
        border-radius: 20px;
        padding: 39px 52px 31px 52px;
        opacity: 1;
        display: none;

        .home_explanation_jian{
          width: 60px;
          height: 60px;
          box-shadow: 5px -5px 10px rgba(226, 226, 226, 0.5);
          transform: rotate(45deg);
          background: #FFFFFF;
          position: absolute;
          left: 450px;
          top: 60px;
        }

        p{
          width: 374px;
          height: 102px;
          font-size: 20px;
          font-family: Nowar Warcraft Rounded CN;
          font-weight: 400;
          line-height: 35px;
          color: #333333;
          opacity: 1;
        }
      }

      .home_explanation_4{
        position: absolute;
        left: 300px;
        top: 318px;
        width: 374px;
        height: 102px;
        background: #FFFFFF;
        box-shadow: 0px 10px 20px rgba(226, 226, 226, 0.76);
        border-radius: 20px;
        padding: 39px 52px 31px 52px;
        opacity: 1;
        display: none;

        .home_explanation_jian{
          width: 60px;
          height: 60px;
          box-shadow: 5px -5px 10px rgba(226, 226, 226, 0.5);
          transform: rotate(45deg);
          background: #FFFFFF;
          position: absolute;
          left: 450px;
          top: 60px;
        }

        p{
          width: 374px;
          height: 102px;
          font-size: 20px;
          font-family: Nowar Warcraft Rounded CN;
          font-weight: 400;
          line-height: 35px;
          color: #333333;
          opacity: 1;
        }
      }

      .home_explanation_5{
        position: absolute;
        left: 768px;
        top: 508px;
        width: 374px;
        height: 102px;
        background: #FFFFFF;
        box-shadow: 0px 10px 20px rgba(226, 226, 226, 0.76);
        border-radius: 20px;
        padding: 39px 52px 31px 52px;
        opacity: 1;
        display: none;

        .home_explanation_jian{
          width: 60px;
          height: 60px;
          box-shadow: -5px 5px 10px rgba(226, 226, 226, 0.5);
          transform: rotate(45deg);
          background: #FFFFFF;
          position: absolute;
          left: -30px;
          top: 60px;
        }

        p{
          width: 374px;
          height: 102px;
          font-size: 20px;
          font-family: Nowar Warcraft Rounded CN;
          font-weight: 400;
          line-height: 35px;
          color: #333333;
          opacity: 1;
        }
      }

      .home_explanation_6{
        position: absolute;
        left: 558px;
        top: 608px;
        width: 374px;
        height: 102px;
        background: #FFFFFF;
        box-shadow: 0px 10px 20px rgba(226, 226, 226, 0.76);
        border-radius: 20px;
        padding: 39px 52px 31px 52px;
        opacity: 1;
        display: none;

        .home_explanation_jian{
          width: 60px;
          height: 60px;
          box-shadow: 5px -5px 10px rgba(226, 226, 226, 0.5);
          transform: rotate(45deg);
          background: #FFFFFF;
          position: absolute;
          left: 450px;
          top: 60px;
        }

        p{
          width: 374px;
          height: 102px;
          font-size: 20px;
          font-family: Nowar Warcraft Rounded CN;
          font-weight: 400;
          line-height: 35px;
          color: #333333;
          opacity: 1;
        }
      }
    }
  }
}

.home_class_system{

  .home_series{
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content:center;
    -ms-flex-pack: center;
    margin-top: 60px;

    div{
      width: 216px;
      height: 61px;
      border-radius: 45px;
      opacity: 1;
      cursor: pointer;

      p{
        font-size: 20px;
        font-family: PingFang SC;
        line-height: 60px;
        opacity: 1;
        text-align: center;
      }
    }

    .home_series_1 ,.home_series_2 ,.home_series_3 ,.home_series_4{
      margin-right: 30px;
    }

    .home_series_1{
      background: #F59F1A;

      p{
        font-weight: bold;
        color: #FFFFFF;
      }
    }

    .home_series_2 ,.home_series_3 ,.home_series_4 ,.home_series_5{
      background: #EEEEEE;

      p{
        font-weight: 500;
        color: #333333;
      }
    }
  }
}

.home_series_one{
  background: #FFFBE8;
  padding: 60px 0;
  margin-top: 68px;
  position: relative;
  
  .home_series_jian{
    width: 83px;
    height: 83px;
    background: #FFFBE8;
    opacity: 1;
    transform: rotate(45deg);
    position: absolute;
    left: 420px;
    top: -40px;
  }

  .home_series_first{
    width: 1200px;
    background: #FFFFFF;
    opacity: 1;
    border-radius: 20px;
    margin: auto;
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content:center;
    -ms-flex-pack: center;
    padding-top: 60px;
    padding-bottom: 60px;

    .home_series_img_1{
      margin-right: 60px;
    }

    .home_series_img_2{
      width: 536px;
      position: relative;

      h3{
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 60px;
        color: #333333;
        opacity: 1;
      }

      .home_series_img_3{
        position: absolute;
        right: 0px;
        top: -20px;
      }

      .home_series_p_1{
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 28px;
        color: #333333;
        opacity: 1;
      }
    }

    .home_series_img_4{
      width: 136px;
      background: #F59F1A;
      opacity: 1;
      border-radius: 20px 0px 20px 0px;

      p{
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 35px;
        color: #FFFFFF;
        opacity: 1;
        text-align: center;
        margin-top: 45px;
      }
    }

    .home_series_img_5{
      margin-top: 53px;
      overflow: hidden;

      img{
        float: left;
      }

      span{
        float: left;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 18px;
        color: #333333;
        opacity: 1;
        margin-left: 10px;
      }
    }

    .home_series_img_6{
      margin-top: 15px;
      overflow: hidden;

      img{
        float: left;
      }

      span{
        width: 495px;
        float: left;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 18px;
        color: #333333;
        opacity: 1;
        margin-left: 10px;

        p{
          margin-top: 15px;
        }
      }
    }
  }
}

.home_series_two{
  background: #FFFBE8;
  padding: 60px 0;
  margin-top: 68px;
  position: relative;
  display: none;
  
  .home_series_jian{
    width: 83px;
    height: 83px;
    background: #FFFBE8;
    opacity: 1;
    transform: rotate(45deg);
    position: absolute;
    left: 665px;
    top: -40px;
  }

  .home_series_first{
    width: 1200px;
    background: #FFFFFF;
    opacity: 1;
    border-radius: 20px;
    margin: auto;
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content:center;
    -ms-flex-pack: center;
    padding-top: 60px;
    padding-bottom: 60px;

    .home_series_img_1{
      margin-right: 60px;
    }

    .home_series_img_2{
      width: 536px;
      position: relative;

      h3{
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 60px;
        color: #333333;
        opacity: 1;
      }

      .home_series_img_3{
        position: absolute;
        right: 0px;
        top: -20px;
      }

      .home_series_p_1{
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 28px;
        color: #333333;
        opacity: 1;
      }
    }

    .home_series_img_4{
      width: 136px;
      background: #F59F1A;
      opacity: 1;
      border-radius: 20px 0px 20px 0px;

      p{
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 35px;
        color: #FFFFFF;
        opacity: 1;
        text-align: center;
        margin-top: 45px;
      }
    }

    .home_series_img_5{
      margin-top: 53px;
      overflow: hidden;

      img{
        float: left;
      }

      span{
        float: left;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 18px;
        color: #333333;
        opacity: 1;
        margin-left: 10px;
      }
    }

    .home_series_img_6{
      margin-top: 15px;
      overflow: hidden;

      img{
        float: left;
      }

      span{
        width: 495px;
        float: left;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 18px;
        color: #333333;
        opacity: 1;
        margin-left: 10px;
      }
    }
  }
}

.home_series_three{
  background: #FFFBE8;
  padding: 60px 0;
  margin-top: 68px;
  position: relative;
  display: none;
  
  .home_series_jian{
    width: 83px;
    height: 83px;
    background: #FFFBE8;
    opacity: 1;
    transform: rotate(45deg);
    position: absolute;
    left: 910px;
    top: -40px;
  }

  .home_series_first{
    width: 1200px;
    background: #FFFFFF;
    opacity: 1;
    border-radius: 20px;
    margin: auto;
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content:center;
    -ms-flex-pack: center;
    padding-top: 60px;
    padding-bottom: 60px;

    .home_series_img_1{
      margin-right: 60px;
    }

    .home_series_img_2{
      width: 536px;
      position: relative;

      h3{
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 60px;
        color: #333333;
        opacity: 1;
      }

      .home_series_img_3{
        position: absolute;
        right: 0px;
        top: -20px;
      }

      .home_series_p_1{
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 28px;
        color: #333333;
        opacity: 1;
      }
    }

    .home_series_img_4{
      width: 136px;
      background: #F59F1A;
      opacity: 1;
      border-radius: 20px 0px 20px 0px;

      p{
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 35px;
        color: #FFFFFF;
        opacity: 1;
        text-align: center;
        margin-top: 45px;
      }
    }

    .home_series_img_5{
      margin-top: 53px;
      overflow: hidden;

      img{
        float: left;
      }

      span{
        float: left;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 18px;
        color: #333333;
        opacity: 1;
        margin-left: 10px;
      }
    }

    .home_series_img_6{
      margin-top: 15px;
      overflow: hidden;

      img{
        float: left;
      }

      span{
        width: 495px;
        float: left;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 18px;
        color: #333333;
        opacity: 1;
        margin-left: 10px;
      }
    }
  }
}

.home_series_four{
  background: #FFFBE8;
  padding: 60px 0;
  margin-top: 68px;
  position: relative;
  display: none;
  
  .home_series_jian{
    width: 83px;
    height: 83px;
    background: #FFFBE8;
    opacity: 1;
    transform: rotate(45deg);
    position: absolute;
    left: 1155px;
    top: -40px;
  }

  .home_series_first{
    width: 1200px;
    background: #FFFFFF;
    opacity: 1;
    border-radius: 20px;
    margin: auto;
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content:center;
    -ms-flex-pack: center;
    padding-top: 60px;
    padding-bottom: 60px;

    .home_series_img_1{
      margin-right: 60px;
    }

    .home_series_img_2{
      width: 536px;
      position: relative;

      h3{
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 60px;
        color: #333333;
        opacity: 1;
      }

      .home_series_img_3{
        position: absolute;
        right: 0px;
        top: -20px;
      }

      .home_series_p_1{
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 28px;
        color: #333333;
        opacity: 1;
      }
    }

    .home_series_img_4{
      width: 136px;
      background: #F59F1A;
      opacity: 1;
      border-radius: 20px 0px 20px 0px;

      p{
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 35px;
        color: #FFFFFF;
        opacity: 1;
        text-align: center;
        margin-top: 45px;
      }
    }

    .home_series_img_5{
      margin-top: 53px;
      overflow: hidden;

      img{
        float: left;
      }

      span{
        float: left;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 18px;
        color: #333333;
        opacity: 1;
        margin-left: 10px;

        p{
          margin-top: 15px;
        }
      }
    }

    .home_series_img_6{
      margin-top: 15px;
      overflow: hidden;

      img{
        float: left;
      }

      span{
        width: 495px;
        float: left;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 18px;
        color: #333333;
        opacity: 1;
        margin-left: 10px;
      }
    }
  }
}

.home_series_five{
  background: #FFFBE8;
  padding: 60px 0;
  margin-top: 68px;
  position: relative;
  display: none;
  
  .home_series_jian{
    width: 83px;
    height: 83px;
    background: #FFFBE8;
    opacity: 1;
    transform: rotate(45deg);
    position: absolute;
    left: 1405px;
    top: -40px;
  }

  .home_series_first{
    width: 1200px;
    background: #FFFFFF;
    opacity: 1;
    border-radius: 20px;
    margin: auto;
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content:center;
    -ms-flex-pack: center;
    padding-top: 60px;
    padding-bottom: 60px;

    .home_series_img_1{
      margin-right: 60px;
    }

    .home_series_img_2{
      width: 536px;
      position: relative;

      h3{
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 60px;
        color: #333333;
        opacity: 1;
      }

      .home_series_img_3{
        position: absolute;
        right: 0px;
        top: -20px;
      }

      .home_series_p_1{
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 33px;
        color: #333333;
        opacity: 1;
      }
    }

    .home_series_img_4{
      width: 186px;
      background: #F59F1A;
      opacity: 1;
      border-radius: 20px 0px 20px 0px;
      margin-bottom: 18px;

      p{
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 35px;
        color: #FFFFFF;
        opacity: 1;
        text-align: center;
      }
    }

    .home_series_img_5{
      margin-top: 15px;
      overflow: hidden;

      img{
        float: left;
      }

      span{
        float: left;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 18px;
        color: #333333;
        opacity: 1;
        margin-left: 10px;
      }
    }

    .home_series_img_6{
      margin-top: 15px;
      overflow: hidden;

      img{
        float: left;
      }

      span{
        width: 495px;
        float: left;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 18px;
        color: #333333;
        opacity: 1;
        margin-left: 10px;
      }
    }
  }
}

.teacherteam{

  .TeacherT{
    width: 1218px;
    margin: auto;
  }
}

.home_teachmode{
  background: #FFFBE8;

  .teachWay{
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content:center;
    -ms-flex-pack: center;
    margin-top: 50px;
    padding-bottom: 100px;

    .way{
      width: 255px;

      img{
        width: 160px;
        height: 160px;
        margin-left: 48px;
      }

      h4{
        font-size: 24px;
        font-family: Nowar Warcraft Rounded CN;
        font-weight: 500;
        line-height: 33px;
        color: #333333;
        opacity: 1;
        text-align: center;
        margin-top: 16px;
      }

      p{
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 30px;
        color: #777777;
        opacity: 1;
        margin-top: 20px;
      }
    }

    .home_way{
      margin: 0 219px;
    }
  }
}

.home_five{

  .home_FiveTeach{
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content:center;
    -ms-flex-pack: center;
    margin-top: 60px;
  }
}

.aboutyoyo{

  .AboutYOYO{
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content:center;
    -ms-flex-pack: center;
    margin-top: 60px;

    .boyANDgirl{
      width: 576px;
      margin-left: 30px;
      
      p{
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 46px;
        color: #333333;
        opacity: 1;
        text-align: justify;
        text-indent: 30px;
      }

      .home_boy{
        margin-top: 60px;
      }

      .home_girl{
        margin-top: 50px;
      }
    }
  }
}

.home_number{
  background: #F7F9FF;
  padding-top: 127px;
  padding-bottom: 63px;
  margin-top: 100px;
  overflow: hidden;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-justify-content:center;
  -ms-flex-pack: center;

  .home_chance{

    h3{
      font-size: 100px;
      font-family: PingFang SC;
      font-weight: 800;
      line-height: 40px;
      color: #2762CC;
      opacity: 1;
      
      span{
        font-size: 30px;
      }
    }

    p{
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 40px;
      color: #333333;
      opacity: 1;
      text-align: center;
      margin-top: 20px;
    }
  }

  .home_chance_1{
    margin-right: 311px;
    margin-left: 368px;
  }
}

.home_problem{

  .home_problem_1 ,.home_problem_2 ,.home_problem_3{
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content:center;
    -ms-flex-pack: center;
    width: 1200px;
    background: #F7FBFF;
    opacity: 1;
    border-radius: 20px;
    margin: auto;
    padding: 34px 0;
    cursor: pointer;

    p{
      font-size: 24px;
      font-family: Nowar Warcraft Rounded CN;
      font-weight: 500;
      line-height: 33px;
      color: #333333;
    }

    .home_problem_img{
      padding-top: 6px;

      img{
        width: 34px;
        height: 20px;
      }
    }
  }

  .home_problem_1{
    margin-top: 85px;

    p{
      margin-right: 906px;
    }
  }

  .home_problem_2{
    margin-top: 40px;

    p{
      margin-right: 592px;
    }
  }
  
  .home_problem_3{
    margin-top: 40px;

    p{
      margin-right: 836px;
    }
  }

  .home_answer_1 ,.home_answer_2 ,.home_answer_3{
    width: 1023px;
    margin: 40px auto;
    margin-bottom: 40px;
    background: #EEF6FF;
    opacity: 1;
    border-radius: 20px;
    padding: 20px 137px 20px 40px;
    border-bottom: 14px solid #FFE15D;
    position: relative;
    display: none;

    .home_answer_jian{
      position: absolute;
      left: 100px;
      top: -20px;
      width: 40px;
      height: 40px;
      background: #EEF6FF;
      transform: rotate(45deg);
    }

    p{
      font-size: 20px;
      font-family: Nowar Warcraft Rounded CN;
      font-weight: 500;
      line-height: 60px;
      color: #333333;
      opacity: 1;
    }
  }
}

.home_add{
  margin-top: 100px;
  background-image: url('../images/code_back.png');
  overflow: hidden;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-justify-content:center;
  -ms-flex-pack: center;

  .home_addTeacher{
    padding-top: 50px;
    padding-bottom: 40px;
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-justify-content:center;
    -ms-flex-pack: center;

    .home_add_WeiXin{
      margin-right: 342px;
      margin-top: 50px;

      h3{
        font-size: 30px;
        font-family: Nowar Warcraft Rounded CN;
        font-weight: bold;
        line-height: 60px;
        color: #333333;
        opacity: 1;

        span{
          font-size: 40px;
          color: #FFCB31;
        }
      }

      p{
        margin-top: 20px;
        font-size: 20px;
        font-family: Nowar Warcraft Rounded CN;
        font-weight: 500;
        line-height: 40px;
        color: #333333;
        opacity: 1;
      }
    }
  }
}

@media screen and (max-width: 1650px){
  .home_series_one{
    position: relative;
    
    .home_series_jian{
      width: 83px;
      height: 83px;
      background: #FFFBE8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 280px;
      top: -40px;
    }
  }

  .home_series_two{
    position: relative;
    
    .home_series_jian{
      width: 83px;
      height: 83px;
      background: #FFFBE8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 530px;
      top: -40px;
    }
  }
  
  .home_series_three{
    position: relative;
    
    .home_series_jian{
      width: 83px;
      height: 83px;
      background: #FFFBE8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 775px;
      top: -40px;
    }
  }
  
  .home_series_four{
    position: relative;
    
    .home_series_jian{
      width: 83px;
      height: 83px;
      background: #FFFBE8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 1020px;
      top: -40px;
    }
  }
  
  .home_series_five{
    position: relative;
    
    .home_series_jian{
      width: 83px;
      height: 83px;
      background: #FFFBE8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 1270px;
      top: -40px;
    }
  }
}

@media screen and (max-width: 1400px){
  .home_series_one{
    position: relative;
    
    .home_series_jian{
      width: 83px;
      height: 83px;
      background: #FFFBE8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 150px;
      top: -40px;
    }
  }

  .home_series_two{
    position: relative;
    
    .home_series_jian{
      width: 83px;
      height: 83px;
      background: #FFFBE8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 400px;
      top: -40px;
    }
  }
  
  .home_series_three{
    position: relative;
    
    .home_series_jian{
      width: 83px;
      height: 83px;
      background: #FFFBE8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 640px;
      top: -40px;
    }
  }
  
  .home_series_four{
    position: relative;
    
    .home_series_jian{
      width: 83px;
      height: 83px;
      background: #FFFBE8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 890px;
      top: -40px;
    }
  }
  
  .home_series_five{
    position: relative;
    
    .home_series_jian{
      width: 83px;
      height: 83px;
      background: #FFFBE8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 1140px;
      top: -40px;
    }
  }
}

@media screen and (max-width: 1100px){
  .home_series_one{
    position: relative;
    
    .home_series_jian{
      width: 83px;
      height: 83px;
      background: #FFFBE8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 90px;
      top: -40px;
    }
  }

  .home_series_two{
    position: relative;
    
    .home_series_jian{
      width: 83px;
      height: 83px;
      background: #FFFBE8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 340px;
      top: -40px;
    }
  }
  
  .home_series_three{
    position: relative;
    
    .home_series_jian{
      width: 83px;
      height: 83px;
      background: #FFFBE8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 580px;
      top: -40px;
    }
  }
  
  .home_series_four{
    position: relative;
    
    .home_series_jian{
      width: 83px;
      height: 83px;
      background: #FFFBE8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 830px;
      top: -40px;
    }
  }
  
  .home_series_five{
    position: relative;
    
    .home_series_jian{
      width: 83px;
      height: 83px;
      background: #FFFBE8;
      opacity: 1;
      transform: rotate(45deg);
      position: absolute;
      left: 1080px;
      top: -40px;
    }
  }
}